<template>
  <v-avatar color="primary" size="34">
    <svg id="Layer_1"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 196 196"
         style="enable-background:new 0 0 196 196;"
         xml:space="preserve">
      <path class="st0"
            d="M84.4,185.1c-2,0-4.1-0.1-6.1-0.3c-18.9-1.7-35.4-10.9-47.8-26.7l0,0c-13.9-17.7-19.2-39.8-15.1-62.3
      C19.4,74.3,31.6,53.5,50,37.1c20.5-18.3,45.3-27.7,67.9-25.7c18.9,1.7,35.4,10.9,47.8,26.7c13.9,17.7,19.2,39.8,15.1,62.3
      c-3.9,21.5-16.2,42.4-34.5,58.7C127.5,175.9,105.3,185.1,84.4,185.1z M36.1,153.8c11.2,14.2,26,22.6,42.9,24.1
      c20.7,1.8,43.5-6.9,62.6-23.9c17.2-15.3,28.6-34.8,32.3-54.8c3.8-20.8-0.9-40.4-13.7-56.7c-11.2-14.2-26-22.6-42.9-24.1
      c-20.7-1.8-43.5,6.9-62.6,23.9C37.5,57.6,26,77.1,22.3,97.1C18.5,117.9,23.3,137.5,36.1,153.8L36.1,153.8z" />
      <g>
        <path class="st1"
              d="M84.6,171.2c-18.8,0-36-6.1-49.6-17.7c-16.1-13.8-24.9-33.8-24.9-56.3c0-22.6,8.9-42.6,24.9-56.3
        c16.9-14.4,39.4-20.3,63.6-16.6c23.2,3.6,45,13,61.4,26.5c16.8,13.8,26,30.3,26,46.4c0,16.1-9.2,32.6-26,46.4
        c-16.4,13.5-38.3,22.9-61.4,26.5C93.9,170.8,89.2,171.2,84.6,171.2z M84.6,30.1c-17.1,0-32.8,5.5-45,16
        c-14.5,12.4-22.5,30.5-22.5,51s8,38.6,22.5,51c15.3,13.1,35.9,18.4,58,15c45-7,81.5-36.6,81.5-66c0-29.4-36.6-59-81.5-66
        C93.2,30.5,88.8,30.1,84.6,30.1z" />
        <path class="st2"
              d="M114.3,188.1c-23.6,0-48.1-12.2-68.9-34.4C7.6,113.4,2.5,62.4,33.1,29.7C46.2,15.6,62.7,8.2,80.9,8.1
        c0,0,0.1,0,0.1,0c23.6,0,48.1,12.2,68.9,34.4c37.8,40.3,42.9,91.3,12.3,124C149,180.7,132.4,188.1,114.3,188.1
        C114.3,188.1,114.3,188.1,114.3,188.1z M80.9,15.1C80.9,15.1,80.9,15.1,80.9,15.1c-16.2,0-30.9,6.7-42.7,19.3
        C24,49.6,18,68.8,20.7,90.1c2.6,20.2,13.1,41.1,29.7,58.8l0,0c19.2,20.5,42.5,32.2,63.8,32.2c0,0,0,0,0.1,0
        c16.1,0,30.9-6.7,42.7-19.3c14.1-15.1,20.2-34.4,17.5-55.6c-2.6-20.2-13.1-41.1-29.7-58.8C125.5,26.9,102.3,15.1,80.9,15.1z" />
      </g>
      <path class="st3"
            d="M126.6,128.5V89.6c0-4.7-0.5-10.4-6.7-10.4c-3.4,0-6.6,1.2-9.8,3.3c0.2,1.9,0.4,3.9,0.4,5.9v27.4
      c0,2,0.1,2.3,1.9,2.5l4.8,0.7v9.5H92.2V89c0-4.5-0.4-9.8-6.4-9.8c-3.6,0-7.6,1.8-10,3.9v32.7c0,2,0.1,2,1.9,2.4l5.9,0.8v9.5H50.3
      V119l5.4-0.8c1.7-0.2,1.9-0.6,1.9-2.4V80.3c0-1.3-0.4-2.2-1.6-2.5l-6.5-2.3l1.3-9.8h21.2l1,6.3l0.6,0.1c5.4-4.5,11.6-7.6,19.3-7.6
      c5.9,0,11.3,1.6,14.7,7.2c4-3.1,11-7.2,19.4-7.2c12.9,0,17.7,6.4,17.7,20.4v30.8c0,1.9,0.1,2.3,1.9,2.5l6,0.7v9.5H126.6z" />
    </svg>
  </v-avatar>
</template>

<script>
  export default {

  }
</script>

<style>
  .st0{fill:#005493;}
  .st1{fill:#4CD3D4;}
  .st2{fill:#76D6FF;enable-background:new    ;}
  .st3{fill:#FFFFFF;}
  .v-avatar{
    padding: 5px
  }
</style>
